import {
  ActionKind,
  CustomizeAction,
  CustomizeFetchPayload,
  CustomizeUpdatePayload,
  customizeInitState,
  CustomizeState,
} from "./interfaces"

export const customizeReducer = (state: CustomizeState, action: CustomizeAction): CustomizeState => {
  const { type, payload } = action

  if (type === ActionKind.CUSTOMIZE_MENU) {
    return {
      ...state,
      menu: (payload as CustomizeState)?.menu ?? customizeInitState.menu
    }
  } else if (type === ActionKind.CUSTOMIZE_CARROUSEL_UPDATE) {
    const { locationId, num, datas } = payload as CustomizeUpdatePayload

    const newUpdate = { ...state.carousel }

    newUpdate[locationId] ??= {}
    newUpdate[locationId][num] = {
      date: Date.now(),
      fetch: false,
      datas
    }

    return {
      ...state,
      carousel: newUpdate
    }

  } else if (type === ActionKind.CUSTOMIZE_CARROUSEL_FETCH) {
    const { locationId, num } = payload as CustomizeFetchPayload
    const newUpdate = { ...state.carousel }

    newUpdate[locationId] ??= {}
    newUpdate[locationId][num] = {
      fetch: true,
    }

    return {
      ...state,
      carousel: newUpdate
    }
  } else if (type === ActionKind.CUSTOMIZE_CARROUSEL_PARTNER_UPDATE) {
    const { locationId, num, datas } = payload as CustomizeUpdatePayload

    const newUpdate = { ...state.carouselPartner }

    newUpdate[locationId] ??= {}
    newUpdate[locationId][num] = {
      date: Date.now(),
      fetch: false,
      datas
    }

    return {
      ...state,
      carouselPartner: newUpdate
    }

  } else if (type === ActionKind.CUSTOMIZE_CARROUSEL_PARTNER_FETCH) {
    const { locationId, num } = payload as CustomizeFetchPayload
    const newUpdate = { ...state.carouselPartner }

    newUpdate[locationId] ??= {}
    newUpdate[locationId][num] = {
      fetch: true,
    }

    return {
      ...state,
      carouselPartner: newUpdate
    }
  } else if (type === ActionKind.CUSTOMIZE_BATCH) {
    return {
      ...state,
      ...payload,
    }
  }

  return state
}

export const setCustomizeMenu = (payload: Pick<CustomizeState, "menu">): CustomizeAction => ({ type: ActionKind.CUSTOMIZE_MENU, payload })
export const updateCarousel = (payload: CustomizeUpdatePayload): CustomizeAction => ({ type: ActionKind.CUSTOMIZE_CARROUSEL_UPDATE, payload })
export const fetchCarousel = (payload: CustomizeFetchPayload): CustomizeAction => ({ type: ActionKind.CUSTOMIZE_CARROUSEL_FETCH, payload })
export const updateCarouselPartner = (payload: CustomizeUpdatePayload): CustomizeAction => ({ type: ActionKind.CUSTOMIZE_CARROUSEL_PARTNER_UPDATE, payload })
export const fetchCarouselPartner = (payload: CustomizeFetchPayload): CustomizeAction => ({ type: ActionKind.CUSTOMIZE_CARROUSEL_PARTNER_FETCH, payload })
export const batch = (payload: Partial<CustomizeState>): CustomizeAction => ({ type: ActionKind.CUSTOMIZE_BATCH, payload })
export const resetCustomize = (): CustomizeAction => ({ type: ActionKind.CUSTOMIZE_BATCH, payload: customizeInitState })
