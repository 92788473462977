import { ActionKind, ProductAction, productInitState, ProductState } from "./interfaces"

export const productReducer = (state: ProductState, action: ProductAction): ProductState => {
  const { type, payload } = action

  switch (type) {
    case ActionKind.PRODUCT_OPEN:
      return {
        ...state,
        filter: payload?.filter ?? productInitState.filter,
        type: payload?.type ?? productInitState.type,
        open: true
      }

    case ActionKind.PRODUCT_CLOSE:
      return {
        ...state,
        open: false
      }

    case ActionKind.PRODUCT_CAN_OPEN:
      return {
        ...state,
        canOpen: payload?.canOpen ?? productInitState.canOpen,
      }

    case ActionKind.PRODUCT_SNACKBAR_UNPAID:
      return {
        ...state,
        snackbarUnpaid: payload?.snackbarUnpaid ?? productInitState.snackbarUnpaid,
      }

    case ActionKind.PRODUCT_BATCH:
      return {
        ...state,
        ...payload,
      }

    default:
      return state
  }
}

export const openBillingDialog = (payload: Pick<ProductState, "filter" | "type">): ProductAction => ({ type: ActionKind.PRODUCT_OPEN, payload })
export const closeBillingDialog = (): ProductAction => ({ type: ActionKind.PRODUCT_CLOSE })
export const setCanOpenBillingDialog = (payload: Pick<ProductState, "canOpen">): ProductAction => ({ type: ActionKind.PRODUCT_CAN_OPEN, payload })
export const setSnackbarUnpaid = (payload: Pick<ProductState, "snackbarUnpaid">): ProductAction => ({ type: ActionKind.PRODUCT_SNACKBAR_UNPAID, payload })
export const batch = (payload: Partial<ProductState>): ProductAction => ({ type: ActionKind.PRODUCT_BATCH, payload })
export const resetProduct = (): ProductAction => ({ type: ActionKind.PRODUCT_BATCH, payload: productInitState })
