import { Dispatch } from "react"

export enum ActionKind {
  CONTACT_OPEN = 'CONTACT_OPEN',
  CONTACT_STACK_URL = 'CONTACT_STACK_URL',
  CONTACT_ADD_EXTRA = 'CONTACT_ADD_EXTRA',
  CONTACT_REPORT_TYPE_LIST = 'CONTACT_REPORT_TYPE_LIST',
  CONTACT_REPORT_TYPE = 'CONTACT_REPORT_TYPE',
  CONTACT_BATCH = 'CONTACT_BATCH',
}

export interface ContactAction {
  type: ActionKind,
  payload: Partial<ContactState>,
}

export interface ContactState {
  open: boolean,
  lastUrl: Array<string>,
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  extra: Array<Record<string, any>>,
  reportTypeList: Array<{
    id: number | string,
    type: string,
    mail_receiver: string,
    name: Record<string, string>,
  }>,
  reportType: string,
  url?: string,
}

export const contactInitState: ContactState = {
  open: false,
  lastUrl: [],
  extra: [],
  reportTypeList: [],
  reportType: 'ux'
}

export type ContactProviderValuesI = {
  contactState: ContactState,
  contactDispatch: Dispatch<ContactAction>,
}
