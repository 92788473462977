import { Dispatch } from "react"

export enum ActionKind {
  PRODUCT_OPEN = 'PRODUCT_OPEN',
  PRODUCT_CLOSE = 'PRODUCT_CLOSE',
  PRODUCT_CAN_OPEN = 'PRODUCT_CAN_OPEN',
  PRODUCT_SNACKBAR_UNPAID = 'PRODUCT_SNACKBAR_UNPAID',
  PRODUCT_BATCH = 'PRODUCT_BATCH',
}

export interface ProductAction {
  type: ActionKind,
  payload?: Partial<ProductState>,
}

export interface ProductState {
  open: boolean,
  canOpen: boolean | null,
  filter: string[],
  type: "pack" | "plan",
  snackbarUnpaid: boolean,
}

export const productInitState: ProductState = {
  open: false,
  canOpen: false,
  snackbarUnpaid: true,
  type: "plan",
  filter: []
}

export type ProductProviderValuesI = {
  productState: ProductState,
  productDispatch: Dispatch<ProductAction>,
}
