import { ContactAction, ActionKind, ContactState, contactInitState } from "./interfaces"

const LIMIT_STACK_URL = 3

export const contactReducer = (state: ContactState, action: ContactAction): ContactState => {
  const { type, payload } = action

  let index = 0
  switch (type) {
    case ActionKind.CONTACT_OPEN:
      return {
        ...state,
        open: payload?.open ?? contactInitState.open,
      }

    case ActionKind.CONTACT_STACK_URL:
      if (state.lastUrl.length >= LIMIT_STACK_URL) {
        state.lastUrl.shift()
        state.extra.shift()
      }
      state.lastUrl.push(payload.url!)
      state.extra.push({})
      return state

    case ActionKind.CONTACT_ADD_EXTRA:
      index = state.extra.length - 1
      state.extra[index] = {
        ...state.extra[index],
        ...payload.extra
      }
      return state

    case ActionKind.CONTACT_REPORT_TYPE_LIST:
      return {
        ...state,
        reportTypeList: payload?.reportTypeList ?? contactInitState.reportTypeList,
      }

    case ActionKind.CONTACT_REPORT_TYPE:
      return {
        ...state,
        reportType: payload?.reportType ?? contactInitState.reportType,
      }

    case ActionKind.CONTACT_BATCH:
      return {
        ...state,
        ...payload,
      }

    default:
      return state
  }
}

export const setOpenContact = (payload: Pick<ContactState, "open">): ContactAction => ({ type: ActionKind.CONTACT_OPEN, payload })
export const stackUrlContact = (payload: Pick<ContactState, "url">): ContactAction => ({ type: ActionKind.CONTACT_STACK_URL, payload })
export const addExtraContact = (payload: Pick<ContactState, "extra">): ContactAction => ({ type: ActionKind.CONTACT_ADD_EXTRA, payload })
export const setReportTypeList = (payload: Pick<ContactState, "reportTypeList">): ContactAction => ({ type: ActionKind.CONTACT_REPORT_TYPE_LIST, payload })
export const setReportType = (payload: Pick<ContactState, "reportType">): ContactAction => ({ type: ActionKind.CONTACT_REPORT_TYPE, payload })
export const batch = (payload: Partial<ContactState>): ContactAction => ({ type: ActionKind.CONTACT_BATCH, payload })
export const resetContact = (): ContactAction => ({ type: ActionKind.CONTACT_BATCH, payload: contactInitState })
