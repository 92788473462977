import { Trans, useTranslation } from "next-i18next"
import { FC, ReactNode, useState } from "react"
import PricesCardTypeBase from "../Base/PricesCardTypeBase"
import { formatPrice } from "@saas-utils/tools"
import { PricesCardPropsI } from "../../interfaces"
import { Stripe } from "stripe"
import PricesCustomBox from "@saas-molecules/Prices/CustomBox/PricesCustomBox"
import ButtonUI from "@lib-ui/atoms/ButtonUI/ButtonUI"
import useSWR from 'swr'
import Link from "next/link"
import AnalyticsServices from "@saas-services/AnalyticsServices"

const PricesCardTypeSubscription: FC<PricesCardPropsI> = (props) => {
  const { item, external } = props
  let { trialDay: trialDayRaw } = props
  const { t, i18n } = useTranslation("stripe")

  const product: Stripe.Product = item.product as Stripe.Product
  const minQuantity = Number(product.metadata['minQuantity'] ?? 1)
  const maxQuantity = Number(product.metadata['maxQuantity'] ?? 99999)
  const [quantity, setQuantity] = useState<number>(minQuantity)

  let trialDay = Number(item.recurring?.trial_period_days ?? product.metadata['trial_period_days'] ?? 0)
  const { data } = useSWR(!trialDayRaw ? `/api/public/subscription/trial?day=${trialDay}` : null)
  trialDay = trialDayRaw ?? data?.trialDay ?? 0

  const interval = item.recurring!.interval

  const calculPrice = (price: Stripe.Price, quantity: number): number => {
    let total = 0
    if (price.tiers_mode === 'graduated') {
      const tiers = price.tiers!

      let baseStep = 0
      tiers.every(tier => {
        const upTo = tier.up_to ?? quantity
        const step = (upTo < quantity ? upTo : quantity) - baseStep

        const basePrice = Number(tier?.unit_amount_decimal ?? 0)
        total += (basePrice / 100) * step

        baseStep = upTo
        return upTo < quantity
      })

    } else if (price.tiers_mode === 'volume') {
      const tiers = price.tiers!

      const filterTier = tiers.filter(tier => {
        return !tier?.up_to || quantity <= tier.up_to
      })
      const pick = filterTier ? filterTier.shift() : null
      const basePrice = Number(tiers ? pick?.unit_amount_decimal : item?.unit_amount_decimal) ?? 0
      total = (basePrice / 100) * quantity
    } else {
      total = (Number(price.unit_amount_decimal ?? 0) / 100) * quantity
    }
    return total
  }

  const total = calculPrice(item, quantity)
  const defaultPrice = total / quantity
  const byYear = defaultPrice
  const byMonth = (interval === 'year') ? (defaultPrice / 12) : byYear
  const multiUser = (product.metadata['multiUser'] ?? 'false') === 'true'

  const totalPrice = (localPrice: number) => {
    return formatPrice(i18n.language, quantity * localPrice, item.currency)
  }

  const getPrice = (): ReactNode => {
    const customPrice = item.metadata['custom_price'] ?? undefined
    return <Trans
      i18nKey={`stripe:subscription.${item.currency === 'eur' ? 'eur' : 'usd'}.${(trialDay ?? 0) > 1 ? 'free' : 'raw'}.per_${multiUser ? 'unit_' : ''}${interval}`}
      values={ {
        month: formatPrice(i18n.language, Number(customPrice ?? byMonth), item.currency),
        year: formatPrice(i18n.language, Number(customPrice ?? byYear), item.currency)
      } }
      components={ { span: <span className="text-dark font-bold text-4xl" />, sup: <sup /> } }
    />
  }

  const highlight: boolean = (product.metadata['highlight'] ?? 'false') === 'true'

  const submitEvent = () => {
    if (!external) {
      AnalyticsServices.pushEvent('payment_initiated')
    }
  }

  const buildButton = (): ReactNode => {
    const button = <ButtonUI
      variant={highlight ? "contained" : "outlined"}
      label={`${t(`stripe:subscription.${item.currency === 'eur' ? 'eur' : 'usd'}.${(trialDay ?? 0) > 1 ? 'free' : 'raw'}.button`)}`}
      type={!external ? 'submit' : 'button'}
      onClick={submitEvent}
      borderRadius=".5em"
      sx={ { fontWeight: "bold" } }
    />
    return !external ? (
      <form action="/api/subscription/checkout_sessions" method="POST">
        <input type="hidden" name="productId" value={product.id} />
        <input type="hidden" name="priceId" value={item.id} />
        <input type="hidden" name="quantity" value={quantity} />
        <input type="hidden" name="mode" value="subscription" />
        {button}
      </form>
    ) : (
      <Link href={`${t('stripe:external_link')}`} locale={i18n.language} target="_blank" className="no-underline">
        {button}
      </Link>
    )
  }
  return <PricesCardTypeBase
    button={buildButton}
    price={getPrice}
    highlight={highlight}
    {...props}
    trialDay={trialDay}
  >
    {multiUser ? <PricesCustomBox
      quantity={quantity}
      setQuantity={setQuantity}
      min={minQuantity}
      max={maxQuantity}
      label={ {
        top: `${t('stripe:subscription.desc')}`,
        minus: `${t('stripe:subscription.minus')}`,
        content: <Trans i18nKey="stripe:subscription.unit" values={ { count: quantity } } />,
        plus: `${t('stripe:subscription.plus')}`,
        bottom: <Trans
          i18nKey={`stripe:subscription.price_${interval}`}
          values={ { year: totalPrice(byYear), month: totalPrice(byMonth) } }
          components={ { span: <span className="font-bold text-2xl" />, br: <br/> } }
        />,
      } }
    /> : null}
  </PricesCardTypeBase>
}

export default PricesCardTypeSubscription
