import { FC, useContext, useEffect, useState } from "react"
import dayjs from 'dayjs'
import { BillingProviderPropsI } from "./interfaces"
import Dialog from "@mui/material/Dialog"
import IconButton from "@mui/material/IconButton"
import { IconCreditCard, IconX } from "@tabler/icons-react"
import DialogContent from "@mui/material/DialogContent"
import { useRouter } from "next/router"
import { removeQueryParam } from "@saas-utils/tools"
import Box from "@mui/material/Box"
import Tooltip from "@mui/material/Tooltip"
import styles from "./BillingProvider.module.scss"
import { Trans, useTranslation } from "next-i18next"
import Snackbar from "@mui/material/Snackbar"
import Alert from "@mui/material/Alert"
import AlertTitle from "@mui/material/AlertTitle"
import SubscriptionPage from "@saas-pages/Subscription/SubscriptionPage"
import { useSession } from "next-auth/react"
import useSWR from "swr"
import { SubscriptionPropsI } from "@saas-repositories/allready/interfaces"
import { useUser } from "@saas-hooks"
import Link from "next/link"
import AnalyticsServices from "@saas-services/AnalyticsServices"
import ReducerContext from "@saas-reducers/ReducerContext"
import { batch, openBillingDialog, setSnackbarUnpaid } from "@saas-reducers/Product"

const BillingProvider: FC<BillingProviderPropsI> = ({ children }: BillingProviderPropsI) => {
  const router = useRouter()

  const { status } = useSession()
  const { t } = useTranslation("stripe")
  const { user, refreshUser, isLoading: isUserLoading } = useUser()
  const { productState: state, productDispatch: dispatch } = useContext(ReducerContext)
  const { data: subscriptions, isLoading } = useSWR<Array<SubscriptionPropsI>>('/api/user/subscriptions')
  const [snackbar, setSnackbar] = useState<string|null>(null)

  const isTrial = () => !!subscriptions?.find(sub => sub.status === 'trialing' && sub.subscription_id === 'sub_trial')
  const isInternalTrial = () => !!subscriptions?.find(sub => sub.subscription_id === 'sub_trial')

  const unpaid = () => Number(subscriptions?.filter(items => ["past_due", "unpaid"].includes(items.status))?.length) > 0
  const incomplete = () => Number(subscriptions?.filter(items => ["incomplete"].includes(items.status))?.length) > 0

  const canManage = () => (user?.roles ?? []).includes(process.env.NEXT_PUBLIC_ROLES_MANAGE_ID ?? "")

  useEffect(() => {
    const { success, aborted } = router.query

    if (canManage() && !isLoading) {
      if (success || aborted) {
        if (success) {
          setSnackbar(success as string)
          refreshUser()
          dispatch(batch({
            open: false,
            canOpen: false,
          }))
          if (!isInternalTrial()) {
            AnalyticsServices.pushEvent('subscription')
          }
        } else {
          dispatch(batch({
            open: true,
            canOpen: true,
            type: aborted === 'payment' ? 'pack' : 'plan',
            filter: [],
          }))
        }
        removeQueryParam(router, ['success', 'aborted'])
      } else if (!subscriptions?.length && !user?.token?.length && state.canOpen === null) {
        dispatch(openBillingDialog({ filter: [], type: "plan" }))
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, state.canOpen, router, user, isLoading, subscriptions])

  useEffect(() => {
    const fetchClient = () => {
      fetch('/api/subscription/client')
        .then(res => res.json())
        .then(data => {
          if (Object.keys(data).length) {
            refreshUser()
          }
        })
    }

    if (status === 'authenticated' && !user?.customer_billing_id && canManage()) {
      fetchClient()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, user?.customer_billing_id])

  const handleCloseDialog = () => {
    dispatch(batch({
      open: false,
      canOpen: false,
    }))
  }

  const handleCloseSnackbar = () => setSnackbar(null)
  const handleCloseSnackbarUnpaid = () => dispatch(setSnackbarUnpaid({ snackbarUnpaid: false }))

  const buildSnackBar = () => {
    if (incomplete()) {
      return <Snackbar anchorOrigin={ { vertical: "top", horizontal: "center" } } open={state.snackbarUnpaid}>
        <Alert onClose={handleCloseSnackbarUnpaid} severity="info" className={styles['alert']}>
          {canManage() ? (
            <Link href="/api/subscription/customer_portal" className={styles['link']}>
              {t('stripe:msg.incomplete')}
            </Link>
          ) : (
            <>{t('stripe:msg.incomplete_subuser')}</>
          )}
        </Alert>
      </Snackbar>
    } else if (unpaid()) {
      return <Snackbar anchorOrigin={ { vertical: "top", horizontal: "center" } } open={state.snackbarUnpaid}>
        <Alert onClose={handleCloseSnackbarUnpaid} severity="error" className={styles['alert']}>
          {canManage() ? (
            <Link href="/api/subscription/customer_portal" className={styles['link']}>
              {t('stripe:msg.unpaid')}
            </Link>
          ) : (
            <>{t('stripe:msg.unpaid_subuser')}</>
          )}
        </Alert>
      </Snackbar>
    } else if (canManage()) {
      let trial = 7
      if (subscriptions?.length) {
        const trialDate = dayjs(subscriptions[0].trial_end).minute(0).second(0).millisecond(0)
        const now = dayjs().hour(0).minute(0).second(0).millisecond(0)
        trial = trialDate.diff(now, 'day')
      }
      let label = <>{t('stripe:billingPage.desc')}</>
      if (snackbar === 'payment') {
        label = <>{t('stripe:billingPage.paymentDesc')}</>
      } else if (isTrial()) {
        label = <Trans
          i18nKey="stripe:billingPage.trialDesc"
          values={ { count: trial } }
          components={ { br: <br />, strong: <strong /> } }
        />
      }

      return <Snackbar anchorOrigin={ { vertical: "top", horizontal: "center" } } open={snackbar !== null}>
        <Alert onClose={handleCloseSnackbar} severity="success" className={styles['alert']}>
          <AlertTitle>{t('stripe:billingPage.title')}</AlertTitle>
          {label}
        </Alert>
      </Snackbar>
    }
    return <></>
  }

  const buildIconBottom = () => {
    if (canManage()) {
      if (incomplete()) {
        return <Box className={`${styles['market-place-button']} ${styles['incomplete']}`}>
          <Tooltip arrow title={t('stripe:icon.incomplete')} placement="top">
            <Link href="/api/subscription/customer_portal" className={styles['link']}>
              <IconButton
                id="incomplete-button"
                aria-controls={state.open ? `incomplete-button` : undefined}
                aria-haspopup="true"
                aria-expanded={state.open ? 'true' : undefined}
              >
                <IconCreditCard size="2em" />
              </IconButton>
            </Link>
          </Tooltip>
        </Box>
      } else if (unpaid()) {
        return <Box className={`${styles['market-place-button']} ${styles['unpaid']}`}>
          <Tooltip arrow title={t('stripe:icon.unpaid')} placement="top">
            <Link href="/api/subscription/customer_portal" className={styles['link']}>
              <IconButton
                id="unpaid-button"
                aria-controls={state.open ? `unpaid-button` : undefined}
                aria-haspopup="true"
                aria-expanded={state.open ? 'true' : undefined}
              >
                <IconCreditCard size="2em" />
              </IconButton>
            </Link>
          </Tooltip>
        </Box>
      } else {
        return <>
          <Dialog
            onClose={handleCloseDialog}
            open={state.open}
            fullWidth={true}
            maxWidth={state.type === "pack" ? "lg" : "xxl"}
          >
            <IconButton
              aria-label="close"
              onClick={handleCloseDialog}
              sx={ {
                position: 'absolute',
                right: 0,
                top: 0,
                color: (theme) => theme.palette.grey[500],
              } }
            >
              <IconX stroke={1.25} />
            </IconButton>
            <DialogContent className={styles['dialog']}>
              {state.open && <SubscriptionPage
                filterProject={state.filter}
                location_id={user?.location_id!}
                hidePack={state.type === "plan"}
                hidePlan={state.type === "pack"}
              />}
            </DialogContent>
          </Dialog>
        </>
      }
    }
    return <></>
  }

  return <>
    {children}
    {!isLoading && !isUserLoading ? (
      <>
        {buildIconBottom()}
        {buildSnackBar()}
      </>
    ) : null}
  </>
}

export default BillingProvider
