import { FC } from 'react'
import Button from "@mui/material/Button"
import { ButtonPropsI } from './interfaces'
import styles from './ButtonUI.module.scss'

const ButtonUI: FC<ButtonPropsI> = ({
  className = '',
  label,
  backgroundColor,
  borderRadius,
  variant = 'contained',
  ...props
}: ButtonPropsI) => (
  <Button
    className={`button ${className} ${styles[variant]} ${styles['button']}`}
    variant={variant}
    style={ {
      borderRadius,
      backgroundColor
    } }
    {...props}
  >
    {label}
  </Button>
)

export default ButtonUI