import { FC, ReactNode } from "react"
import { PricesCardPropsI } from '../../interfaces'
import PricesCardTypeBase from "../Base/PricesCardTypeBase"
import { Stripe } from "stripe"
import ButtonUI from "@lib-ui/atoms/ButtonUI/ButtonUI"
import { Trans, useTranslation } from "next-i18next"
import { formatPrice } from "@saas-utils/tools"

const PricesCardTypePackage: FC<PricesCardPropsI> = (props) => {
  const { item } = props
  const { t, i18n } = useTranslation("stripe")
  const product: Stripe.Product = item.product as Stripe.Product
  const initQuantity = item.transform_quantity?.divide_by ?? 10

  const highlight: boolean = (product.metadata['highlight'] ?? 'false') === 'true'

  const getPrice = (): ReactNode => {
    const customPrice = item.metadata['custom_price'] ?? ((item?.unit_amount ?? 0) / 100)

    const trialDay = 0
    return <Trans
      i18nKey={`stripe:package.${item.currency === 'eur' ? 'eur' : 'usd'}.${(trialDay ?? 0) > 1 ? 'free' : 'raw'}.label`}
      values={ {
        price: formatPrice(i18n.language, Number(customPrice), item.currency),
        token: initQuantity
      } }
      components={ { span: <span className="text-dark font-bold text-4xl" />, sup: <sup /> } }
    />
  }

  const buildButton = (): ReactNode =>
    <form action="/api/subscription/checkout_sessions" method="POST">
      <input type="hidden" name="productId" value={product.id} />
      <input type="hidden" name="priceId" value={item.id} />
      <input type="hidden" name="quantity" value={item.transform_quantity?.divide_by ?? 1} />
      <input type="hidden" name="mode" value="payment" />
      <ButtonUI
        variant={highlight ? "contained" : "outlined"}
        label={`${t('stripe:buy_package')}`}
        type="submit"
        borderRadius=".5em"
      />
    </form>

  return <PricesCardTypeBase
    button={buildButton}
    price={getPrice}
    highlight={highlight}
    featuresList={() => null}
    {...props}
  />
}

export default PricesCardTypePackage
