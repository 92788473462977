import { FC } from "react"
import { ChipUIPropsI } from "./interfaces"
import styles from './ChipUI.module.scss'
import { getBgColor, getBorderColor, getTxtColor } from "@saas-utils/tailwindcss"

const ChipUI: FC<ChipUIPropsI> = ({
  label,
  color = 'default',
  variant = "filled",
  size = "medium",
  onClick,
  onDelete,
}) => {

  const className = new Array<string>()
  className.push('rounded-full')

  if (variant === 'outlined') {
    className.push('border')

    if (color !== 'default') {
      className.push(getTxtColor(color))
      className.push(getBorderColor(color))
    } else {
      className.push(styles['default'])
    }
  } else {
    if (color !== 'default') {
      className.push(getBgColor(color))
    } else {
      className.push(styles['default'])
    }
  }

  if (size === 'medium') {
    className.push('p-1')
  }

  return <div className={`${styles['chipUI']} mx-1 ${className.join(' ')} ${onClick ? 'cursor-pointer' : ''} content-center`}>
    <span className="px-2 py-0.5" onClick={onClick}>{label}</span>
    {onDelete && <div className={`${styles['close']}`} onClick={onDelete}>
      <svg focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="CancelIcon">
        <path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59z"></path>
      </svg>
    </div>}
  </div>
}

export default ChipUI
