import { Dispatch } from "react"
import { TypeValues } from "@saas-organisms/Form/Project/interfaces"

export enum ActionKind {
  CUSTOMIZE_MENU = 'CUSTOMIZE_MENU',
  CUSTOMIZE_CARROUSEL_UPDATE = 'CUSTOMIZE_CARROUSEL_UPDATE',
  CUSTOMIZE_CARROUSEL_FETCH = 'CUSTOMIZE_CARROUSEL_FETCH',
  CUSTOMIZE_CARROUSEL_PARTNER_UPDATE = 'CUSTOMIZE_CARROUSEL_PARTNER_UPDATE',
  CUSTOMIZE_CARROUSEL_PARTNER_FETCH = 'CUSTOMIZE_CARROUSEL_PARTNER_FETCH',
  CUSTOMIZE_BATCH = 'CUSTOMIZE_BATCH',
}

export interface CustomizeAction {
  type: ActionKind,
  payload?: Partial<CustomizeState> | CustomizeUpdatePayload | CustomizeFetchPayload,
}

export interface CustomizeState {
  menu: boolean,
  nbNewText: number,
  carousel: Record<string, Record<number, FetchDatasCarouselPropsI>>,
  carouselPartner: Record<string, Record<number, FetchDatasCarouselPropsI>>,
}

export interface FetchDatasCarouselPropsI {
  date?: number,
  fetch: boolean,
  datas?: Array<FetchDataCarouselPropsI>,
}

export interface FetchDataCarouselPropsI {
  projects: {
    internal_code: string,
  },
  config: Record<string, TypeValues>,
  id: number,
  ref?: string,
  aggIds?: Array<number>,
  projectName: string,
}

export const customizeInitState: CustomizeState = {
  menu: false,
  nbNewText: 0,
  carousel: {},
  carouselPartner: {},
}

export type CustomizeFetchPayload = {
  locationId: string,
  num: number,
}

export type CustomizeUpdatePayload = {
  locationId: string,
  num: number,
  datas: Array<FetchDataCarouselPropsI>,
}

export type CustomizeProviderValuesI = {
  customizeState: CustomizeState,
  customizeDispatch: Dispatch<CustomizeAction>,
}
