import '@saas-styles/index.scss';
import { SessionProvider } from "next-auth/react";
import { appWithTranslation, useTranslation } from 'next-i18next';
import type { AppProps } from 'next/app';
import { ThemeProvider, useTheme } from "@mui/material/styles";
import { Session } from "next-auth";
import { allready } from "@saas-themes/index";
import AppProvider from "@saas-organisms/Provider/AppProvider/AppProvider";
import { ContextProvider } from "@saas-organisms/Provider/ContextProvider/ContextProvider";
import { SWRConfig } from 'swr';
// @ts-ignore
import nextI18nConfig from '../next-i18next.config.js';
import LoaderUI from "@saas-atoms/LoaderUI/LoaderUI";
const MyApp = ({
  Component,
  pageProps
}: AppProps) => {
  const theme = useTheme();
  const {
    ready
  } = useTranslation(["translation", "meta", "stripe", "pa", "com", "custom", "desc", "error"]);
  if (!ready) return <LoaderUI color={theme.palette.primary.main} size={30} center />;
  return <SessionProvider session={(pageProps as {
    session: Session;
  }).session}>
    <ContextProvider>
      <ThemeProvider theme={allready}>
        <SWRConfig value={{
          revalidateOnFocus: true,
          fetcher: (resource, init) => fetch(resource, init).then(res => res.json())
        }}>
          <AppProvider pageProps={pageProps}>
            <Component {...pageProps} />
          </AppProvider>
        </SWRConfig>
      </ThemeProvider>
    </ContextProvider>
  </SessionProvider>;
};
export default appWithTranslation(MyApp, nextI18nConfig);