import { FC, useContext, useEffect } from "react"
import { AppProviderPropsI } from "./interfaces"
import { removeQueryParam } from "@saas-utils/tools"
import { useRouter } from "next/router"
import AnalyticsServices from "@saas-services/AnalyticsServices"
import BillingProvider from "@saas-organisms/Provider/BillingProvider/BillingProvider"
import { useUser } from "@saas-hooks"
import { stackUrlContact } from "@saas-reducers/Contact"
import ReducerContext from "@saas-reducers/ReducerContext"

const AppProvider: FC<AppProviderPropsI> = ({ pageProps, children }: AppProviderPropsI) => {
  const router = useRouter()
  const { user, isLoading, refreshUser } = useUser()
  const { contactDispatch: dispatch } = useContext(ReducerContext)

  useEffect(() => {
    AnalyticsServices.initialize()
  }, [])

  useEffect(() => {
    if (!isLoading) {
      AnalyticsServices.user(user)
    }
  }, [dispatch, user, isLoading])

  useEffect(() => {
    AnalyticsServices.track()
    dispatch(stackUrlContact({ url: window.location.href }))
  }, [dispatch, pageProps])

  useEffect(() => {
    if (typeof window !== "undefined") {
      const params = new URLSearchParams(window.location.search)

      if (params?.has('update')) {
        removeQueryParam(router, ['update'])
        refreshUser().catch(console.error)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router])

  return <BillingProvider pageProps={pageProps}>
    {children}
  </BillingProvider>
}

export default AppProvider
