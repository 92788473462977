import { FC } from 'react'
import { PricesCardPropsI } from './interfaces'
import { Stripe } from "stripe"
import PricesCardTypePackageCustom from "./Type/PackageCustom/PricesCardTypePackageCustom"
import PricesCardTypePackage from "./Type/Package/PricesCardTypePackage"
import PricesCardTypeSubscription from "./Type/Subscription/PricesCardTypeSubscription"
import PricesCardTypeContact from "@saas-organisms/Cards/Prices/Type/Contact/PricesCardTypeContact"

const PricesCard: FC<PricesCardPropsI> = (props) => {

  const type = props.item.type
  const product: Stripe.Product = props.item.product as Stripe.Product
  const quantity = (product.metadata['quantity'] ?? "false") === "true"
  const isContact = (product.metadata['contact'] ?? 'false') === 'true'

  if (isContact) {
    return <PricesCardTypeContact {...props} />
  } else if (type === 'one_time') {
    return quantity ? <PricesCardTypePackageCustom {...props} /> :
      <PricesCardTypePackage {...props} />
  }
  return <PricesCardTypeSubscription {...props} />
}

export default PricesCard
