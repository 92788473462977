import { createTheme } from '@mui/material/styles'
import variables from '@saas-style-variables'

declare module '@mui/material/styles' {
  interface Typography {
    typography: {
      fontFamily: string[],
    },
  }
  // allow configuration using `createTheme`
  interface TypographyOptions {
    typography?: {
      fontFamily?: string[],
    },
  }
  interface BreakpointOverrides {
    xs: true, // add the `xs` breakpoint
    sm: true,
    md: true,
    lg: true,
    xl: true,
    xxl: true,
    '3xl': true,
    '4xl': true,
    '4k': true,
    mobile: false, // remove the `mobile` breakpoint
    tablet: false,
    laptop: false,
    desktop: false,
  }
  interface Palette {
    tertiary: Palette['primary'],
    dark: Palette['primary'],
  }
  interface PaletteOptions {
    tertiary: PaletteOptions['primary'],
    dark: PaletteOptions['primary'],
  }
}

export const allready = createTheme({
  typography: {
    // fontWeightRegular: 'bolder',
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      'Segoe UI',
      'Roboto',
      'Oxygen',
      'Ubuntu',
      'Cantarell',
      'Fira Sans',
      'Droid Sans',
      'Helvetica Neue',
      'sans-serif'
    ].join(','),
  },
  palette: {
    primary: {
      main: variables.primaryColor
    },
    secondary: {
      main: variables.secondaryColor
    },
    error: {
      main: variables.errorColor
    },
    warning: {
      main: variables.warningColor
    },
    info: {
      main: variables.infoColor
    },
    success: {
      main: variables.successColor
    },
    tertiary: {
      main: variables.tertiaryColor
    },
    dark: {
      main: variables.darkColor
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: "transparent"
        }
      }
    }
  },
  breakpoints: {
    values: {
      xs: Number(variables.breakpointXS),
      sm: Number(variables.breakpointSM),
      md: Number(variables.breakpointMD),
      lg: Number(variables.breakpointLG),
      xl: Number(variables.breakpointXL),
      xxl: Number(variables.breakpointXXL),
      '3xl': Number(variables.breakpoint3XL),
      '4xl': Number(variables.breakpoint4XL),
      '4k': Number(variables.breakpoint4k),
    },
  }
})
