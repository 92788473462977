import { BeatLoader } from 'react-spinners'
import LoaderI from './interfaces'

const LoaderUI = ({
  color,
  size,
  center = false
}: LoaderI) => {
  const loader = <BeatLoader color={color} loading={true} size={size} />

  return center ? (
    <div className="flex min-h-screen items-center justify-center">{loader}</div>
  ) : (
    <div>{loader}</div>
  )
}

export default LoaderUI
