import { PricesCardPropsI } from '../../interfaces'
import { Trans, useTranslation } from "next-i18next"
import { FC, ReactNode, useState } from "react"
import PricesCardTypeBase from "../Base/PricesCardTypeBase"
import { formatPrice } from "@saas-utils/tools"
import { Stripe } from "stripe"
import PricesCustomBox from "@saas-molecules/Prices/CustomBox/PricesCustomBox"
import ButtonUI from "@lib-ui/atoms/ButtonUI/ButtonUI"

const PricesCardTypePackageCustom: FC<PricesCardPropsI> = (props) => {
  const { item } = props

  const { t, i18n } = useTranslation("stripe")
  const initQuantity = item.transform_quantity?.divide_by ?? 10
  const [quantity, setQuantity] = useState<number>(initQuantity)
  const product: Stripe.Product = item.product as Stripe.Product

  const highlight: boolean = (product.metadata['highlight'] ?? 'false') === 'true'

  const totalPrice = () => {
    const divide_by = initQuantity
    const round = item.transform_quantity?.round ?? ""
    const count = (((item.unit_amount ?? 0) / 100) * quantity) / divide_by
    let price: number
    switch (round) {
      case "down":
        price = Math.floor(count)
        break
      case "up":
        price = Math.ceil(count)
        break
      default:
        price = Math.round(count)
        break
    }
    return formatPrice(i18n.language, price, item.currency)
  }

  const buildButton = (): ReactNode =>
    <form action="/api/subscription/checkout_sessions" method="POST">
      <input type="hidden" name="productId" value={product.id} />
      <input type="hidden" name="priceId" value={item.id} />
      <input type="hidden" name="quantity" value={quantity} />
      <input type="hidden" name="mode" value="payment" />
      <ButtonUI
        variant={highlight ? "contained" : "outlined"}
        label={`${t('stripe:custom_package.button')}`}
        type="submit"
        borderRadius=".5em"
      />
    </form>

  return <PricesCardTypeBase
    button={buildButton}
    featuresList={() => null}
    highlight={highlight}
    price={() => <PricesCustomBox
      quantity={quantity}
      setQuantity={setQuantity}
      label={ {
        top: `${t('stripe:custom_package.desc')}`,
        minus: `${t('stripe:custom_package.minus', { token: initQuantity })}`,
        content: `${t('stripe:custom_package.unit', { token: quantity })}`,
        plus: `${t('stripe:custom_package.plus', { token: initQuantity })}`,
        bottom: <Trans
          i18nKey="stripe:custom_package.price"
          values={ { price: totalPrice() } }
          components={ { span: <span className="text-lg font-bold text-dark" />, br: <br/> } }
        />,
      } }
      step={initQuantity}
    />}
    description={() => null}
    {...props}
  >

  </PricesCardTypeBase>
}

export default PricesCardTypePackageCustom
