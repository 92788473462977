import { FC } from "react"
import { PricesCardPropsI } from '../../interfaces'
import { Trans, useTranslation } from "next-i18next"
import ButtonUI from "@lib-ui/atoms/ButtonUI/ButtonUI"
import Grid from "@mui/material/Unstable_Grid2"
import { Stripe } from "stripe"
import styles from "./PricesCardTypeContact.module.scss"
import Link from "next/link"

const PricesCardTypeContact: FC<PricesCardPropsI> = ({ item }: PricesCardPropsI) => {
  const { t, i18n } = useTranslation('stripe')

  const product: Stripe.Product = item.product as Stripe.Product
  const i18n_code: string = product.metadata['i18n'] ?? ""

  return <Grid container className={styles['container']}>
    <Grid className={styles['title']}>
      <Trans
        i18nKey={`stripe:products.${i18n_code}.name`}
        default={product?.name ?? ""}
        components={ { br: <br />, small: <small />, sup: <sup /> } }
      />
    </Grid>
    <Grid className={styles['desc']}>
      <Trans
        i18nKey={`stripe:products.${i18n_code}.description`}
        default={product?.description ?? ""}
        components={ { b: <b /> } }
      />
    </Grid>
    <Grid>
      <Link href={`${t('stripe:contact_link')}`} locale={i18n.language} target="_blank" className={styles['link']}>
        <ButtonUI label={t('stripe:contact')} type="button" />
      </Link>
    </Grid>
  </Grid>
}

export default PricesCardTypeContact
