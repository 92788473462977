import { FC, ReactNode, useContext } from "react"
import { Stripe } from "stripe"
import { Trans, useTranslation } from "next-i18next"
import { SubscriptionPlanPropsI } from "./interfaces"
import styles from "./SubscriptionPlan.module.scss"
import PricesCard from "@saas-organisms/Cards/Prices/PricesCard"
import TabsUI from "@lib-ui/organisms/TabsUI/TabsUI"
import ArrowImg from '@saas-icons/arrow.ico.svg'
import { formatPrice } from "@saas-utils/tools"
import ChipUI from "@saas-atoms/ChipUI/ChipUI"
import ButtonUI from "@lib-ui/atoms/ButtonUI/ButtonUI"
import ReducerContext from "@saas-reducers/ReducerContext"
import { openBillingDialog } from "@saas-reducers/Product"

const SubscriptionPlan: FC<SubscriptionPlanPropsI> = ({
  products,
  location_id = null,
  trialDay = undefined,
  filterProject = [],
  external = false,
  autoFontSize = true,
}: SubscriptionPlanPropsI) => {
  const { t, i18n } = useTranslation("stripe")

  const { productDispatch } = useContext(ReducerContext)

  const handleSubscribe = () => {
    productDispatch(openBillingDialog({ filter: [], type: "pack" }))
  }

  const buildSubTab = (configs: Array<Stripe.Price>) => {
    return configs.map(item => {
      const product = item.product as Stripe.Product

      if (filterProject?.length > 0 && !filterProject?.includes(product.id)) {
        return null
      }

      const isContact = (product.metadata['contact'] ?? 'false') === 'true'
      const highlight: boolean = (product.metadata['highlight'] ?? 'false') === 'true'

      return <div key={item.id} className={`${!highlight && !isContact ? 'mt-[2.5em]' : ''} ${isContact ? 'md:basis-full' : 'md:basis-1/3'} p-2`}>
        <PricesCard item={item} trialDay={trialDay} external={external} />
      </div>
    })
  }

  const customTab = (children: ReactNode) => {
    return <div className={styles['container-tab']}>
      <div className={styles['content-tab']}>
        {children}
        <div className={styles['offer']}>
          <ChipUI label={t('stripe:subscription.offer')} color="primary" variant="outlined" />
          <ArrowImg className={styles['arrow']} />
        </div>
      </div>
    </div>
  }

  return <div className={`${styles['payment']} ${!autoFontSize ? styles['autoFontSize'] : null}`}>
    <div className={styles['container']}>
      <TabsUI
        items={Object.entries(products)}
        content={([, config]) =>
          <div className="flex flex-wrap items-stretch">
            {buildSubTab(config)}
          </div>}
        formatLabel={([key,]: Array<string>) => t(`stripe:tabs.recurring_${key}`)}
        customTab={customTab}
        className={styles['tabs-round']}
        tabsConfig={ {
          centered: true,
          className: styles['tab'],
          TabIndicatorProps: {
            className: styles['indicator']
          }
        } }
      />
    </div>
    <div className="mt-2 flex justify-between">
      <div className="pl-2 text-grey text-sm">
        <Trans
          i18nKey={`stripe:subscription.${location_id === 73 ? "eur" : 'usd'}.description`}
          values={ {
            price_crm: formatPrice(i18n.language, 99, location_id === 73 ? "eur" : 'usd'),
          } }
          components={ { br: <br />, sup: <sup /> } }
        />
      </div>
      {!external && <ButtonUI
        label={t('stripe:billingSubscription:buy-button')}
        variant="text"
        size="large"
        onClick={() => handleSubscribe()}
      />}
    </div>
  </div>
}

export default SubscriptionPlan
