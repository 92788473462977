import { FC, SyntheticEvent, useState } from 'react'
import Box from '@mui/material/Box'
import Tabs from "@mui/material/Tabs"
import Tab from "@mui/material/Tab"
import TabsUIPropsI from './interfaces'

const TabsUI: FC<TabsUIPropsI> = ({
  items,
  content,
  className,
  formatLabel,
  customTab = val => val,
  tabsConfig,
  tabContentConfig
}: TabsUIPropsI) => {
  const [value, setValue] = useState<number>(0)

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  return <>
    {items.length > 1 ? customTab(
      <Box className={className}>
        <Tabs value={value} onChange={handleChange} {...tabsConfig}>
          {items.map((key, idx) =>
            <Tab
              value={idx}
              label={formatLabel ? formatLabel(key) : idx}
              key={typeof key === 'string' ? key : idx}
            />
          )}
        </Tabs>
      </Box>
    ) : null}
    {items.map((key, idx) =>
      <Box
        role="tabpanel"
        hidden={value !== idx}
        id={`simple-tabpanel-${idx}`}
        aria-labelledby={`simple-tab-${idx}`}
        key={typeof key === 'string' ? key : idx}
        sx={ { p: 0 } }
        {...tabContentConfig}
      >
        {value === idx && content(key)}
      </Box>
    )}
  </>
}

export default TabsUI
