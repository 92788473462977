import { FC, ReactNode } from 'react'
import { PricesCardPropsI } from '../../interfaces'
import { Stripe } from "stripe"
import styles from "./PricesCardTypeBase.module.scss"
import { formatPrice } from "@saas-utils/tools"
import { Trans, useTranslation } from "next-i18next"
import ButtonUI from "@lib-ui/atoms/ButtonUI/ButtonUI"
import parse from "html-react-parser"

const PricesCardTypeBase: FC<PricesCardPropsI> = ({
  item,
  trialDay = 0,
  price = (defaultPrice?: ReactNode) => defaultPrice,
  name = (defaultName?: ReactNode) => defaultName,
  description = (defaultDescription?: ReactNode) => defaultDescription,
  featuresList = (defaultFeatureList?: ReactNode) => defaultFeatureList,
  button = (defaultButton?: ReactNode) => defaultButton,
  highlight = false,
  children
}: PricesCardPropsI) => {
  const { t, i18n } = useTranslation("stripe")

  const defaultPrice: number = (item?.unit_amount ?? 0) / 100
  const product: Stripe.Product = item.product as Stripe.Product
  const i18n_code: string = product.metadata['i18n'] ?? ""

  const getPrice = (): ReactNode | string | undefined => {
    return price(formatPrice(i18n.language, defaultPrice, item.currency))
  }

  const getDescription = (): ReactNode | string | undefined => {
    return description(
      <Trans
        i18nKey={`stripe:products.${i18n_code}.description`}
        default={product?.description ?? ""}
        components={ { b: <b/>, br: <br/> } }
      />
    )
  }

  const getName = (): ReactNode | string | undefined => {
    return name(
      <Trans
        i18nKey={`stripe:products.${i18n_code}.name`}
        default={product?.name ?? ""}
        values={ {
          count: trialDay
        } }
        components={ { br: <br/>, small: <small />, sup: <sup /> } }
      />
    )
  }

  const getBeforeFeature = (): ReactNode | string | undefined =>
    <>
      <Trans
        i18nKey={`stripe:products.${i18n_code}.before_feature`}
        components={ { br: <br/>, small: <small />, sup: <sup />, b: <b />, p: <p className="mb-2" /> } }
      />
    </>

  const getAfterFeature = (): ReactNode | string | undefined =>
    <>
      <Trans
        i18nKey={`stripe:products.${i18n_code}.after_feature`}
        components={ { br: <br/>, small: <small />, sup: <sup />, b: <b />, p: <p className="mb-2" /> } }
      />
    </>

  const getFeatureList = (): ReactNode | string | undefined => {
    const feature = t(`stripe:products.${i18n_code}.features_list`, "")

    let content: ReactNode | string | undefined = undefined
    if (feature && feature.length) {
      const featureList = JSON.parse(feature)
      content = <ul>{featureList.map((item: string) => <li key={item}>{parse(item)}</li>)}</ul>
    }

    return featuresList(content)
  }

  const buildButton = () => button(<ButtonUI label={`${t('stripe:contact')}`} borderRadius=".5em" className={styles['action-button']} />)
  const desc = getDescription()

  return (
    <div className="h-full">
      <div className={`${styles['container']} shadow-lg bg-white rounded-lg text-center h-full`}>
        {highlight && <div className={styles['highlight-content']}>{t('stripe:popular')}</div>}
        <div className="p-4">
          <div className={styles['title']}>{getName()}</div>
          <div className={styles['price']}>{getPrice()}</div>
          {desc && <div className={styles['desc']}>{desc}</div>}
          <div className={styles['button']}>{buildButton()}</div>
          <div className={styles['features']}>
            {getBeforeFeature()}
            {getFeatureList()}
            {getAfterFeature()}
          </div>
          {children && <div className={styles['bottom']}>{children}</div>}
        </div>
      </div>
    </div>
  )
}

export default PricesCardTypeBase
