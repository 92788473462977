import React from 'react'
import { ContextProviderValuesI } from "@saas-reducers/interfaces"

const ReducerContext = React.createContext<ContextProviderValuesI>({} as ContextProviderValuesI)

export function useReducerContext() {
  return React.useContext(ReducerContext)
}

export default ReducerContext
