import { FC } from "react"
import { ProductResponsePropsI, SubscriptionPagePropsI } from "./interfaces"
import useSWR from "swr"
import SubscriptionPlan from "@saas-organisms/Subscription/Plan/SubscriptionPlan"
import LoaderUI from "@saas-atoms/LoaderUI/LoaderUI"
import { useTheme } from "@mui/material/styles"
import SubscriptionToken from "@saas-organisms/Subscription/Token/SubscriptionToken"

const SubscriptionPage: FC<SubscriptionPagePropsI> = (props: SubscriptionPagePropsI) => {
  const { datas, hidePack, hidePlan, ...rest } = props

  const theme = useTheme()
  const {
    data: productsRes,
    isLoading,
  } = useSWR<ProductResponsePropsI>(!datas ? '/api/public/subscription/product' : null)

  const products = datas ?? productsRes

  return isLoading && !products ? (
    <LoaderUI color={theme.palette.primary.main} size={30} center />
  ) : (
    <>
      {hidePlan !== true && <SubscriptionPlan products={products?.recurring ?? {}} {...rest} />}
      {hidePack !== true && <SubscriptionToken packs={products?.one_time ?? []} />}
    </>
  )
}

export default SubscriptionPage
