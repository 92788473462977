import { NextRouter } from "next/router"
import dayjs from "dayjs"
import utc from "dayjs/plugin/utc"
import timezone from "dayjs/plugin/timezone"

export const mappingLocaleDayjs: {[locale: string]: string} = {
  'en': 'en-gb',
  'en-GB': 'en-gb',
  'fr-FR': 'fr',
  'en-US': 'en',
  'es-ES': 'es',
  'es-US': 'es-us',
  'it-IT': 'it',
}

export const updateBrowserUrl = (title: string, url: string, query: {[key: string]: string | null}, clean: boolean = false) => {
  const uriParams = !clean ? window.location.search : ""
  const params = new URLSearchParams(uriParams)
  Object.keys(query).forEach(key => {
    if (!query[key]) {
      params.delete(key)
    } else {
      params.set(key, query[key]!)
    }
  })
  let uri = params.toString()
  if (uri.length) {
    uri = `?${uri}`
  }
  window.history.pushState(null, title, url + uri)
}

export const parsePageRowParams = async (
  total: number,
  page?: string | number,
  row?: string | number,
) => {
  let numPage = Number(page ?? '1')
  if (isNaN(numPage) || numPage <= 0) {
    numPage = 1
  }

  let numRow = Number(row ?? '30')
  if (isNaN(numRow) || numPage <= 0) {
    numRow = 30
  }
  if (((numPage - 1) * numRow) > total) {
    numPage = 1
  }
  return { numPage, numRow }
}

export const formatLocaleTimestampToDate = (locale: string, time: number) => {
  dayjs.extend(utc)
  dayjs.extend(timezone)
  let dateObj = dayjs.unix(time).tz('Europe/Paris')
  return dateObj.locale(mappingLocaleDayjs[locale]).format('LL')
}

export const formatLocaleDate = (locale: string, date?: string) => {
  dayjs.extend(utc)
  dayjs.extend(timezone)
  const now = dayjs().tz('Europe/Paris')
  let dateObj = dayjs.tz(date, 'Europe/Paris')
  if (date) {
    if (now.format('YYYY-MM-DD') === dateObj.format('YYYY-MM-DD')) {
      return dateObj.locale(mappingLocaleDayjs[locale]).format('LT')
    } else if (now.year() === dateObj.year()) {
      return dateObj.locale(mappingLocaleDayjs[locale]).format('DD MMM')
    }
  } else {
    dateObj = now
  }

  return dateObj.locale(mappingLocaleDayjs[locale]).format('L')
}

let timer: ReturnType<typeof setTimeout>
export const delay = (callback: () => unknown, ms: number) => {
  return function () {
    // @ts-ignore
    let context = this, args = arguments
    clearTimeout(timer)
    return new Promise((resolve) => {
      timer = setTimeout(function () {
        // @ts-ignore
        resolve(callback.apply(context, args))
      }, ms || 500)
    })
  }
}

export const clearDelay = () => {
  clearTimeout(timer)
}

export const debounce = (func: Function, ms = 300) => {
  let timeout: ReturnType<typeof setTimeout>

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const debounced = (...args: any[]) => {
    clearTimeout(timeout)
    timeout = setTimeout(() => func.apply(this, args), ms)
  }

  debounced.clear = () => clearTimeout(timeout)
  return debounced
}

export const formatPrice = (locale: string, price: number, currency: string = 'eur') => {
  const numberFormater: Intl.NumberFormat = new Intl.NumberFormat(locale ?? "en", {
    style: 'currency',
    currency: currency,
    minimumFractionDigits: 2,
  })
  return numberFormater.format(price).replace(/[,.]00(\s?\D*)?$/, '$1')
}

export const removeQueryParam = (router: NextRouter, param: string[]) => {
  const { pathname, query } = router
  // @ts-ignore
  const params = new URLSearchParams(query)
  param.forEach(item => params.delete(item))
  router.replace(
    { pathname, query: params.toString() },
    undefined,
    { shallow: true }
  )
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isArray = (value: any): boolean => Object.prototype.toString.call(value) === '[object Array]'
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isObject = (value: any): boolean => Object.prototype.toString.call(value) === '[object Object]'
