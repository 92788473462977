
export const getTxtColor = (color: string) => {
  let className
  switch (color) {
    case 'primary':
      className = 'text-primary'
      break
    case 'secondary':
      className = 'text-secondary'
      break
    case 'error':
      className = 'text-error'
      break
    case 'info':
      className = 'text-info'
      break
    case 'success':
      className = 'text-success'
      break
    case 'warning':
      className = 'text-warning'
      break
    default:
      className = 'text-default'
      break
  }
  return className
}

export const getBgColor = (color: string) => {
  let className
  switch (color) {
    case 'primary':
      className = 'bg-primary text-white'
      break
    case 'secondary':
      className = 'bg-secondary'
      break
    case 'error':
      className = 'bg-error'
      break
    case 'info':
      className = 'bg-info'
      break
    case 'success':
      className = 'bg-success'
      break
    case 'warning':
      className = 'bg-warning'
      break
    default:
      className = 'bg-default'
      break
  }
  return className
}

export const getBorderColor = (color: string) => {
  let className
  switch (color) {
    case 'primary':
      className = 'border-primary'
      break
    case 'secondary':
      className = 'border-secondary'
      break
    case 'error':
      className = 'border-error'
      break
    case 'info':
      className = 'border-info'
      break
    case 'success':
      className = 'border-success'
      break
    case 'warning':
      className = 'border-warning'
      break
    default:
      className = 'border-default'
      break
  }
  return className
}