import { Ref, useEffect, useMemo, useRef, useState } from "react"
import useSWR, { useSWRConfig } from 'swr'
import { User } from "next-auth"
import { debounce } from "@saas-utils/tools"

export const useWrapperRef = <T extends HTMLElement>(): [T | null, Ref<T>] => {
  const [wrapper, setWrapper] = useState<T | null>(null)
  const ref = useRef<T>(null)

  useEffect(() => {
    if (ref.current) {
      setWrapper(ref.current)
    }
  }, [])

  return [wrapper, ref]
}

export const useUser = () => {
  const { mutate } = useSWRConfig()

  const { data, isLoading, error } = useSWR<User>(`/api/user`, {
    refreshInterval: 60000,
  })

  return {
    user: data,
    isLoading: isLoading,
    isError: error,
    refreshUser: async () => {
      mutate(`/api/user`).catch(console.error)
      return fetch('/api/auth/session?update').catch(console.error)
    }
  }
}

export const useEventListener = (eventName: string, handler: (event: Event|KeyboardEvent) => void, element?: Window) => {
  // Create a ref that stores handler
  const savedHandler = useRef<(event: Event) => void>()
  if (!element && typeof window !== "undefined") {
    element = window
  }

  // Update ref.current value if handler changes.
  // This allows our effect below to always get latest handler ...
  // ... without us needing to pass it in effect deps array ...
  // ... and potentially cause effect to re-run every render.
  useEffect(() => {
    savedHandler.current = handler
  }, [handler])

  useEffect(
    () => {
      // Make sure element supports addEventListener
      // On
      const isSupported = element && element.addEventListener
      if (!isSupported) return

      // Create event listener that calls handler function stored in ref
      const eventListener = (event: Event) => {
        if (savedHandler.current) {
          savedHandler.current(event)
        }
      }

      // Add event listener
      element?.addEventListener(eventName, eventListener)

      // Remove event listener on cleanup
      return () => {
        element?.removeEventListener(eventName, eventListener)
      }
    },
    [eventName, element] // Re-run if eventName or element changes
  )
}

export const useDebounce = (fnc: Function, ms = 500) =>
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useMemo(() => debounce(fnc, ms), [])
