import { Dispatch } from "react"
import { TypeValues } from "@saas-organisms/Form/Project/interfaces"

export enum ActionKind {
  PROJECT_REMOVE_VALUE_FROM_DATA = 'PROJECT_REMOVE_VALUE_FROM_DATA',
  PROJECT_SET_DATA = 'PROJECT_SET_DATA',
  PROJECT_SET_CURRENT_STEP = 'PROJECT_SET_CURRENT_STEP',
  PROJECT_SET_PROJECT_NAME = 'PROJECT_SET_PROJECT_NAME',
  PROJECT_SET_FROM_MODEL_ID = 'PROJECT_SET_FROM_MODEL_ID',
  PROJECT_SET_FROM_PARTNER_ID = 'PROJECT_SET_FROM_PARTNER_ID',
  PROJECT_SET_PROJECT_RESULT_ID = 'PROJECT_SET_PROJECT_RESULT_ID',
  PROJECT_SET_MAX_STEP_VISITED = 'PROJECT_SET_MAX_STEP_VISITED',
  PROJECT_CLEAR_DATA = 'PROJECT_CLEAR_DATA',
  PROJECT_CLEAR_ALL = 'PROJECT_CLEAR_ALL',
  PROJECT_BATCH = 'PROJECT_BATCH',
}

export const PROJECT_KEY_LS: string = "projectReducer"

export interface ProjectAction {
  type: ActionKind,
  payload?: Partial<ProjectState>,
  clear?: boolean,
}

export interface ProjectState {
  currentStep: number,
  projectResultId: number | null,
  fromModelId: number | null,
  fromPartnerId: {
    partner: string,
    id: number,
    ref: string,
  } | null,
  currentProjectName: string,
  maxStepVisited: {
    [key: number]: number,
  },
  dataCollected: Record<string, TypeValues>,
}

export const projectInitState: ProjectState = {
  currentStep: 1,
  projectResultId: null,
  fromModelId: null,
  fromPartnerId: null,
  currentProjectName: "",
  maxStepVisited: {},
  dataCollected: {},
}

export type ProjectProviderValuesI = {
  projectState: ProjectState,
  projectDispatch: Dispatch<ProjectAction>,
}
