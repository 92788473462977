import React, { FC, useEffect, useReducer } from "react"
import ReducerContext from "@saas-reducers/ReducerContext"
import { ContextProviderPropsI } from "./interfaces"
import { ContextProviderValuesI } from "@saas-reducers/interfaces"
import { resetContact, contactReducer } from "@saas-reducers/Contact"
import { contactInitState } from "@saas-reducers/Contact/interfaces"
import { productReducer, resetProduct } from "@saas-reducers/Product"
import { productInitState } from "@saas-reducers/Product/interfaces"
import { customizeReducer, resetCustomize } from "@saas-reducers/Customize"
import { customizeInitState } from "@saas-reducers/Customize/interfaces"
import { projectInitializer, projectReducer, resetProject } from "@saas-reducers/Project"
import { PROJECT_KEY_LS, projectInitState } from "@saas-reducers/Project/interfaces"

export const ContextProvider: FC<ContextProviderPropsI> = ({ children }: ContextProviderPropsI) => {
  const [contactState, contactDispatch] = useReducer(contactReducer, contactInitState)
  const [productState, productDispatch] = useReducer(productReducer, productInitState)
  const [customizeState, customizeDispatch] = useReducer(customizeReducer, customizeInitState)
  const [projectState, projectDispatch] = useReducer(projectReducer, projectInitState, projectInitializer)

  useEffect(() => {
    if (!projectState) {
      projectDispatch(resetProject())
    } else {
      localStorage.setItem(PROJECT_KEY_LS, JSON.stringify(projectState))
    }
  }, [projectState])

  const logout = () => {
    contactDispatch(resetContact())
    productDispatch(resetProduct())
    customizeDispatch(resetCustomize())
    projectDispatch(resetProject())
  }

  const providerState: ContextProviderValuesI = {
    contactState,
    contactDispatch,
    productState,
    productDispatch,
    customizeState,
    customizeDispatch,
    projectState,
    projectDispatch,
    logout,
  }

  return (
    <ReducerContext.Provider value={providerState}>
      {children}
    </ReducerContext.Provider>
  )
}
