import { ChangeEvent, FC } from "react"
import Tooltip from "@mui/material/Tooltip"
import IconButton from "@mui/material/IconButton"
import { IconCircleMinus, IconCirclePlus } from "@tabler/icons-react"
import { PricesCustomBoxPropsI } from "./interfaces"
import styles from "./PricesCustomBox.module.scss"

const PricesCustomBox: FC<PricesCustomBoxPropsI> = ({
  quantity,
  setQuantity,
  label,
  step = 1,
  min = step,
  max = 100_000,
}: PricesCustomBoxPropsI) => {
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    let value = Number(event.target.value)
    if (value === 0) {
      value = min
    }
    if (value >= min && value <= max) {
      setQuantity(value)
    }
  }

  const handleIncrement = (add: number) => {
    if (quantity < max) {
      setQuantity(quantity + add)
    }
  }

  const handleDecrement = (sub: number) => {
    if (quantity > min) {
      setQuantity(quantity - sub)
    }
  }

  return <div className="flex align-center justify-center items-center pt-2 gap-x-4">
    <div className="basis-1/3 whitespace-nowrap">{label.top}</div>
    <div className="basis-1/3 bg-tertiary rounded-lg p-2">
      <div className="flex flex-row align-center justify-around items-center text-sm">
        <div>
          <Tooltip title={label.minus} placement="top">
            <span>
              <IconButton color="primary" onClick={() => handleDecrement(step)} disabled={quantity === min}>
                <IconCircleMinus />
              </IconButton>
            </span>
          </Tooltip>
        </div>
        <div className="grow">
          <div className={styles['number']}>
            <input
              className={styles['input']}
              type="number"
              min={min}
              max={max}
              value={quantity}
              onChange={handleChange}
            />{label.content}
          </div>
        </div>
        <div>
          <Tooltip title={label.plus} placement="top">
            <span>
              <IconButton color="primary" onClick={() => handleIncrement(step)} disabled={quantity === max}>
                <IconCirclePlus />
              </IconButton>
            </span>
          </Tooltip>
        </div>
      </div>
    </div>
    <div className="basis-1/3 whitespace-nowrap">{label.bottom}</div>
  </div>
}

export default PricesCustomBox
