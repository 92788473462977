import { FC, useContext } from "react"
import { SubscriptionTokenPropsI } from "@saas-organisms/Subscription/Token/interfaces"
import PricesCard from "@saas-organisms/Cards/Prices/PricesCard"
import ButtonUI from "@lib-ui/atoms/ButtonUI/ButtonUI"
import { useTranslation } from "next-i18next"
import ReducerContext from "@saas-reducers/ReducerContext"
import { openBillingDialog } from "@saas-reducers/Product"
import useSWR from "swr"
import { SubscriptionPropsI } from "@saas-repositories/allready/interfaces"
import { Stripe } from "stripe"

const SubscriptionToken: FC<SubscriptionTokenPropsI> = ({
  packs
}: SubscriptionTokenPropsI) => {
  const { t } = useTranslation("stripe")
  const { productDispatch } = useContext(ReducerContext)
  const { data: subscriptions, isLoading: isLoadingSubscriptions } = useSWR<Array<SubscriptionPropsI>>('/api/user/subscriptions')

  const handleSubscribe = () => {
    productDispatch(openBillingDialog({ filter: [], type: "plan" }))
  }

  const buildParamButton = () => {
    const isInternalTrial = !isLoadingSubscriptions ? !!subscriptions?.find(sub => sub.subscription_id === 'sub_trial') : false
    let param
    if (isInternalTrial || !subscriptions?.length) {
      param = {
        label: t('stripe:billingToken:subscribe-button'),
        onClick: () => handleSubscribe()
      }
    } else {
      param = {
        label: t('stripe:billingToken:update-subscribe-button'),
        href: "/api/subscription/customer_portal",
        target: "_blank"
      }
    }
    return param
  }

  const hasHighlight = (() => {
    let pos: null | number = null
    packs.forEach((price, idx) => {
      const product = price.product as Stripe.Product
      const highlight: boolean = (product.metadata['highlight'] ?? 'false') === 'true'
      if (highlight && pos === null) {
        pos = idx
      }
    })
    return pos
  })()

  const isTopHighlight = (() => {
    const pos = hasHighlight
    return pos !== null && pos < 2
  })()

  return <>
    <div className={`flex flex-wrap items-stretch ${!isTopHighlight && hasHighlight ? '-mt-[2.5em]' : ''}`}>
      {packs.map(price => {
        const product = price.product as Stripe.Product
        const highlight: boolean = (product.metadata['highlight'] ?? 'false') === 'true'

        return <div key={price.id} className={`${!highlight && hasHighlight ? 'mt-[2.5em]' : ''} md:basis-1/2 px-2 py-4 w-full`}>
          <PricesCard item={price} trialDay={0} />
        </div>
      })}
    </div>
    <ButtonUI
      variant="text"
      size="large"
      {...buildParamButton()}
    />
  </>
}

export default SubscriptionToken