import { ActionKind, PROJECT_KEY_LS, ProjectAction, projectInitState, ProjectState } from "./interfaces"

export const projectInitializer = (initialValue = projectInitState) => {
  if (typeof window !== 'undefined') {
    initialValue = JSON.parse(localStorage.getItem(PROJECT_KEY_LS) as string) || initialValue
  }
  return initialValue
}

export const projectReducer = (state: ProjectState, action: ProjectAction): ProjectState => {
  const { type, payload, clear } = action

  let newState: ProjectState = {
    ...(clear === true ? projectInitState : state)
  }
  switch (type) {
    case ActionKind.PROJECT_REMOVE_VALUE_FROM_DATA:
      return {
        ...newState,
        dataCollected: {
          ...payload?.dataCollected
        }
      }

    case ActionKind.PROJECT_SET_DATA:
      return {
        ...newState,
        dataCollected: {
          ...newState.dataCollected,
          ...payload?.dataCollected
        }
      }

    case ActionKind.PROJECT_SET_CURRENT_STEP:
      return {
        ...newState,
        currentStep: payload?.currentStep ?? projectInitState.currentStep,
      }

    case ActionKind.PROJECT_SET_PROJECT_NAME:
      return {
        ...newState,
        currentProjectName: payload?.currentProjectName ?? projectInitState.currentProjectName,
      }

    case ActionKind.PROJECT_SET_FROM_MODEL_ID:
      return {
        ...newState,
        fromModelId: payload?.fromModelId ?? projectInitState.fromModelId,
      }

    case ActionKind.PROJECT_SET_FROM_PARTNER_ID:
      return {
        ...newState,
        fromPartnerId: payload?.fromPartnerId ?? projectInitState.fromPartnerId,
      }

    case ActionKind.PROJECT_SET_PROJECT_RESULT_ID:
      return {
        ...newState,
        projectResultId: payload?.projectResultId ?? projectInitState.projectResultId,
      }

    case ActionKind.PROJECT_SET_MAX_STEP_VISITED:
      return {
        ...newState,
        maxStepVisited: payload?.maxStepVisited ?? projectInitState.maxStepVisited,
      }

    case ActionKind.PROJECT_CLEAR_DATA:
      return {
        ...newState,
        dataCollected: {
          ...projectInitState?.dataCollected
        }
      }

    case ActionKind.PROJECT_BATCH:
      return {
        ...newState,
        ...payload,
      }

    default:
      return newState
  }
}

export const removeValueFromData = (payload: Pick<ProjectState, "dataCollected">): ProjectAction => ({ type: ActionKind.PROJECT_REMOVE_VALUE_FROM_DATA, payload })
export const setData = (payload: Pick<ProjectState, "dataCollected">): ProjectAction => ({ type: ActionKind.PROJECT_SET_DATA, payload })
export const setCurrenStep = (payload: Pick<ProjectState, "currentStep">): ProjectAction => ({ type: ActionKind.PROJECT_SET_CURRENT_STEP, payload })
export const setProjectName = (payload: Pick<ProjectState, "currentProjectName">): ProjectAction => ({ type: ActionKind.PROJECT_SET_PROJECT_NAME, payload })
export const setFromModelId = (payload: Pick<ProjectState, "fromModelId">): ProjectAction => ({ type: ActionKind.PROJECT_SET_FROM_MODEL_ID, payload })
export const setFromPartnerId = (payload: Pick<ProjectState, "fromPartnerId">): ProjectAction => ({ type: ActionKind.PROJECT_SET_FROM_PARTNER_ID, payload })
export const setProjectResultId = (payload: Pick<ProjectState, "projectResultId">): ProjectAction => ({ type: ActionKind.PROJECT_SET_PROJECT_RESULT_ID, payload })
export const setMaxStepVisited = (payload: Pick<ProjectState, "maxStepVisited">): ProjectAction => ({ type: ActionKind.PROJECT_SET_MAX_STEP_VISITED, payload })
export const clearData = (): ProjectAction => ({ type: ActionKind.PROJECT_CLEAR_DATA })
export const clearAll = (): ProjectAction => ({ type: ActionKind.PROJECT_CLEAR_ALL, clear: true })
export const batch = (payload: Partial<ProjectState>, clear?: boolean): ProjectAction => ({ type: ActionKind.PROJECT_BATCH, payload, clear })
export const resetProject = (): ProjectAction => ({ type: ActionKind.PROJECT_BATCH, payload: projectInitState })
