import { hotjar } from "react-hotjar"
import PiwikPro, { DataLayer, PageViews, UserManagement } from "@piwikpro/react-piwik-pro"
import { User } from "next-auth"

export default class AnalyticsServices {
  static init: {
    [service: string]: boolean | null,
  } = {
      hotjar: false,
      piwik: false,
    }

  public static initialize = () => {
    if (process.env.NODE_ENV !== 'production' || process.env.NEXT_PUBLIC_ENV !== 'production')
      return

    if (process.env.NEXT_PUBLIC_HJID && process.env.NEXT_PUBLIC_HJSV && AnalyticsServices.init.hotjar === false) {
      hotjar.initialize(Number(process.env.NEXT_PUBLIC_HJID), Number(process.env.NEXT_PUBLIC_HJSV))
      AnalyticsServices.init.hotjar = true
    }

    if (process.env.NEXT_PUBLIC_PIWIK_ID && process.env.NEXT_PUBLIC_PIWIK_URL && AnalyticsServices.init.piwik === false) {
      PiwikPro.initialize(process.env.NEXT_PUBLIC_PIWIK_ID ?? "", process.env.NEXT_PUBLIC_PIWIK_URL ?? "")
      AnalyticsServices.init.piwik = true
    }
  }

  public static user = (user?: User | null) => {
    if (AnalyticsServices.init.hotjar === true) {
      if (user?.id) {
        hotjar.identify(user.id, {
          email: user.email,
          firstname: user.firstname,
          lastname: user.lastname
        })
      } else {
        hotjar.identify('', {})
      }
    }
    if (AnalyticsServices.init.piwik === true) {
      if (user?.id) {
        UserManagement.setUserId(user.id)
      } else {
        UserManagement.resetUserId()
      }
    }
  }

  public static track = () => {
    if (AnalyticsServices.init.piwik === true) {
      PageViews.trackPageView()
    }
  }

  public static pushEvent = (eventName: string) => {
    if (AnalyticsServices.init.piwik === true) {
      DataLayer.push({ event: eventName })
    }
  }
}
